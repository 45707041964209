@import "../../vars.module";

.resultsContainer {
  flex-shrink: 1;
  flex-grow: 0;
  overflow-y: auto;
  overflow-x: hidden;

  position: relative;

  .results {
    height: auto;
  }

  .notFound {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.list {
  .item {
    background: lighten($bgColor, 5);

    .header {
      button {
        background: darken($bgColor, 5);

        .content {
          .displayName, .realName {
            display: inline-block;

            &:first-letter {
              text-transform: uppercase;
            }
          }

          color: black;
          display: flex;
          flex-flow: column;

          .realName {

            opacity: 0.5;
            padding-left: 12px;

          }
        }
      }
    }

    .body {
      display: flex;
      flex-flow: column;
      text-align: left;

      :global(.spinner-border){
        margin-right: 8px;
      }
    }
  }
}