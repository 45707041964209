.container {
  position: relative;
}

.map {
  width: 100%;
  height: 100%;

  @media screen and (max-device-width: 768px) {
    :global(.leaflet-popup-close-button) {
      background: white !important;
      border-radius: 50%;
      width: 36px !important;
      height: 36px !important;
      transform: translate(33%, -33%);
      box-shadow: 0px 0px 2px 0px black;
      font-size: 2em !important;
      line-height: 1.5em !important;
    }
  }

  .heroMarkerTooltip {
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;

    & > * {
      margin: 4px;
    }
  }

  .pharmacy {
    &.maybe {
      opacity: 0.5;
    }
  }

  .pharmacyPopup {
    :global(.leaflet-popup-content) {
      width: 600px !important;
      max-width: 80vw;
    }
  }

  .pharmacyTooltip {
    display: flex;
    flex-flow: column;

    .pharmacyStore {

      a[role=button] {
        margin: 0 4px;

        &:first-of-type {
          margin-left: 0;
        }
      }

      .name {
        &:first-letter {
          text-transform: capitalize;
        }
      }

      .address {
      }

      .phone {
      }

      .hours {
      }
    }

    .item {
      display: flex;
      flex-flow: row;

      .image {
        position: relative;
        width: 100px;
        height: 100px;
        object-fit: contain;
        margin-right: 8px;
        flex-shrink: 0;
        border: 1px solid black;
        box-sizing: border-box;

        &.noImage {
          text-align: center;
          content: attr(data-no-image);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2em;
        }
      }

      .text {
        &:first-letter {
          text-transform: uppercase;
        }

        .maybe {
          font-size: 1.25em;
          color: #a10400;
        }
      }
    }
  }
}


.hidden {
  display: none;
}

.settings {
  background: white;
  position: absolute;

  top: 82px;
  left: 12px;

  width: 30px;
  height: 30px;

  font-size: 18px;
  text-align: center;
  text-decoration: none;
  color: black;

  border-radius: 4px;
  z-index: 800;
  line-height: 24px;

  box-shadow: 0 1px 5px rgba(0,0,0,35%);

  cursor: pointer;
}

.settingsBody {

  .pharmacyList {
    white-space: nowrap;
    span {
      opacity: 0.5;
    }
  }
}
