
//$theme-colors: (
//        "info": tomato,
//        "danger": teal,
//);

@import "~bootstrap/scss/bootstrap";

body,html {
  margin: 0;
  padding: 0;
  height: 100%;
  overscroll-behavior: contain;
}
* {
  box-sizing: border-box;
}

body {
  //height: fill-available;
  //height: -webkit-fill-available;
}
html {
  //height: fill-available;
  //height: -webkit-fill-available;
}

#root {
  height: 100%;
  //display: flex;
  //flex-flow: column;
}