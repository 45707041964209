@import "vars.module";

.App {
  text-align: center;
  background-color: $bgColor;
  color: $color;

  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column;

  .map {
    flex-grow: 1;
    z-index: 0;
  }

  .searchContainer {
    flex-shrink: 1;
    background-color: inherit;

    display: flex;
    flex-flow: column;

    height: auto;

    border-right: 1px solid black;

    &.opened {
      .content {
        height: auto;
        max-height: $open-height;
        min-height: $open-height;
      }
    }

    .content {
      width: 100%;
      height: 100%;
      position: relative;
      max-height: 0;
      min-height: 0;
      transition-duration: 0.15s;
      transition-property: max-height, min-height;
      transition-timing-function: ease-in-out;

      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
    }

    .results {
      height: 100%;
      width: 100%;
    }

    .resultsToggle {
      padding: 4px;
    }

    .search {
      border-radius: 2px;
      font-size: 2rem;
      box-sizing: border-box;

      width: 100%;

      background: rgba(255, 255, 255, 0.3);

      display: flex;
      flex-flow: row;

      input {
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 0;
        outline: none;
      }

      button {
        flex-grow: 0;
        flex-shrink: 0;
        border-radius: 0;
      }
    }

  }
}

@media screen and (min-device-width: 768px) {
  .App {
    flex-flow: row-reverse;

    .searchContainer {
      width: 600px;
      max-width: 40vw;

      .search {

      }

      .resultsToggle {
        display: none;
      }

      .content, .content.opened {
        max-height: unset !important;
        min-height: 0 !important;
        height: 100% !important;
      }
    }
  }
}