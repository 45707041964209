.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;
  height: 100%;

  .item {
    margin: 8px;
    display: flex;
    position: relative;

    text-shadow: 0 0 rgba(0, 0, 0, .1);
    color: rgba(255, 255, 255, 0);

    @keyframes fill-1 {


      75%   { clip: rect(0, 350px, 100px, 0); }
      50%   { clip: rect(0, 0, 100px, 0);     }
      25%   { clip: rect(0px, 300px, 100px, 300px); }
    }

    &:before {
      content: attr(data-text);
      position: absolute;
      overflow: hidden;
      white-space: nowrap;

      clip: rect(0, 0, 100px, 300px);

      animation: fill-1 2s infinite linear;
    }

    &.static {

      &:before {
        animation: none;
        clip: initial;
      }
    }


    .status {
      transform: translateY(-8%);
      padding: 2px;
      margin-left: 4px;
    }

  }
}